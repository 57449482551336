export const vehiclePermissions = {
    IMPORT: {
        key: 'Vehicle.Import',
        name: 'Allow importing assets',
    },
    DELETE: {
        key: 'Vehicle.Delete',
        name: 'Delete assets',
    },
    DELETE_PHOTO: {
        key: 'Vehicle.Delete.Photo',
        name: 'Delete photo in assets ',
    },
    DELETE_ACTIVITY: {
        key: 'Vehicle.Delete.Activity',
        name: 'Delete activity',
    },
    EDIT_VIN_PERMISSIONS: {
        key: 'Permission.EditVIN',
        name: 'Edit VIN permissions',
    },

    // NEW PERMISSION
    ASSETS_PAGE_ADD_ASSET: {
        key: 'assets.page.add.asset',
        name: 'Add Asset',
    },
    ASSETS_PAGE_IMPORT_ASSETS: {
        key: 'assets.page.import.assets',
        name: 'Import Assets',
    },
    ASSETS_PAGE_AUDIT: {
        key: 'assets.page.audit',
        name: 'Audit',
    },
    ASSETS_PAGE_SHORT_REPORT: {
        key: 'assets.page.short.reports.export',
        name: 'Short Report',
    },
    ASSETS_PAGE_EVERYTHING_ON_THE_PAGE_REPORT: {
        key: 'assets.page.everything.on.reports.export',
        name: 'Everything on the Page Report',
    },
    ASSETS_PAGE_LONG_REPORT: {
        key: 'assets.page.long.reports.export',
        name: 'Long Report',
    },
    ASSETS_PAGE_SHOW_MAP: {
        key: 'assets.page.show.map',
        name: 'Show Map',
    },
    ASSETS_PAGE_VIEW_ASSET_TABLE: {
        key: 'assets.page.view.asset.table',
        name: 'View Asset Table',
    },
    ASSETS_PAGE_FLAGS_READ_ONLY: {
        key: 'assets.page.flag.read.only',
        name: 'View Flags (Read Only)',
    },
    ASSETS_PAGE_FLAG_ADD_REMOVE: {
        key: 'assets.page.flag.add.remove',
        name: 'Flag (Add / Remove)',
    },
    ASSETS_PAGE_EDIT: {
        key: 'assets.page.edit',
        name: 'Edit',
    },
    ASSETS_PAGE_DELETE: {
        key: 'assets.page.delete',
        name: 'Delete',
    },
    ASSETS_DETAIL_PAGE_DOWNLOAD_CONDITION_REPORT: {
        key: 'assets.detail.page.download.condition.report',
        name: 'Download Condition Report',
    },
    ASSETS_DETAIL_PAGE_DOWNLOAD_CONDITION_REPORT_FREE_ACCESS: {
        key: 'assets.detail.page.download.condition.report.free.access',
        name: 'Download Condition Report (Free Access)',
    },
    ASSETS_DETAIL_PAGE_CREATE_LOAD: {
        key: 'assets.detail.page.create.load',
        name: 'Create Load',
    },
    ASSETS_DETAIL_PAGE_UPLOAD_DOCUMENT: {
        key: 'assets.detail.page.upload.document',
        name: 'Upload Document',
    },
    ASSETS_DETAIL_PAGE_VIEW_PHOTO: {
        key: 'assets.detail.page.view.photo',
        name: 'View Photo',
    },
    ASSETS_DETAIL_PAGE_EDIT_ADD_PHOTO: {
        key: 'assets.detail.page.edit.add.photo',
        name: 'Edit Photo',
    },
    ASSETS_DETAIL_PAGE_DELETE_PHOTO: {
        key: 'assets.detail.page.delete.photo',
        name: 'Delete Photo',
    },
    ASSETS_DETAIL_PAGE_VIEW_NOTE_PHOTO: {
        key: 'assets.detail.page.view.note.photo',
        name: 'View Note Photo',
    },
    ASSETS_DETAIL_PAGE_EDIT_ADD_NOTE_PHOTO: {
        key: 'assets.detail.page.edit.add.note.photo',
        name: 'Edit/Add Note Photo',
    },
    ASSETS_DETAIL_PAGE_VIEW_NOTE_ASSET: {
        key: 'assets.detail.page.view.note.asset',
        name: 'View Note Asset',
    },
    ASSETS_DETAIL_PAGE_EDIT_ADD_NOTE_ASSET: {
        key: 'assets.detail.page.edit.add.note.asset',
        name: 'Edit/Add Note Asset',
    },
    ASSETS_DETAIL_PAGE_VIEW_ACTIVITY_LOG: {
        key: 'assets.detail.page.view.activity.log',
        name: 'View Activity Log',
    },
    ASSETS_DETAIL_PAGE_ADD_ACTIVITY: {
        key: 'assets.detail.page.add.activity',
        name: 'Add Activity',
    },
    ASSETS_DETAIL_PAGE_EXPORT_CSV: {
        key: 'assets.detail.page.export.csv',
        name: 'Export CSV',
    },
    ASSETS_DETAIL_PAGE_ADD_EDIT_NOTE_ACTIVITY: {
        key: 'assets.detail.page.activity.log.add.edit.note',
        name: 'Add/Edit Activity Notes',
    },
    ASSETS_DETAIL_PAGE_EDIT_ACTIVITY_LOG: {
        key: 'assets.detail.page.activity.log.edit',
        name: 'Edit Activity Log',
    },
    ASSETS_DETAIL_PAGE_ADD_ACTIVITY_LOG: {
        key: 'assets.detail.page.activity.log.add',
        name: 'Add Activity Log',
    },
    ASSETS_DETAIL_PAGE_ACTIVITY_LOG_EXPORT: {
        key: 'assets.detail.page.activity.log.export',
        name: 'Export Activity Log',
    },
    ASSETS_DETAIL_PAGE_DELETE_ACTIVITY_LOG: {
        key: 'assets.detail.page.delete.activity.log',
        name: 'Delete Activity Log',
    },
    ASSETS_DETAIL_PAGE_VIEW_WO_READ_ONLY: {
        key: 'assets.detail.page.activity.log.wo.read.only',
        name: 'View WO (Read Only)',
    },
    ASSETS_DETAIL_PAGE_EDIT_WO: {
        key: 'assets.detail.page.activity.log.wo',
        name: 'Edit WO',
    },
    ASSETS_DETAIL_PAGE_ACTIVITY_DAYS_COMPLETED_IN_COLUMN: {
        key: 'assets.detail.page.activity.log.days.completed.in.column',
        name: 'Days Completed In (Column)',
    },
    ASSETS_DETAIL_PAGE_ACTIVITY_LOG_INTERNAL_COST_COLUMN: {
        key: 'assets.detail.page.activity.log.internal.cost.column',
        name: 'Internal Cost (Column)',
    },
    ASSETS_DETAIL_PAGE_ACTIVITY_LOG_CHARGE_COLUMN: {
        key: 'assets.detail.page.activity.log.charge.column',
        name: 'Charge (Column)',
    }
}